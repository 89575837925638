import { useEffect, useMemo, useRef, useState, type FC } from "react";

import { Clock16Filled } from "@fluentui/react-icons";
import { useToolbar, type AriaToolbarProps } from "@react-aria/toolbar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CheckIcon,
  ChevronsRightIcon,
  MinusIcon,
  PlusCircleIcon,
} from "lucide-react";
import { useSeparator } from "react-aria";
import {
  Collection,
  DialogTrigger,
  TooltipTrigger,
} from "react-aria-components";
import { type Selection } from "react-stately";

import { updateWorkflowData } from "@dokworks/fetch/workflow";
import {
  cn,
  type WorkflowManual,
  type WorkflowStepStatus,
} from "@dokworks/shared";
import {
  ListBox,
  ListBoxItem,
  Popover,
  Tooltip,
  UnstyledButton,
} from "@dokworks/ui";

import { api } from "@/utils/fetch/api";
import { statusText } from "@/utils/helpers/workflowHelper";

type WorkflowStagesProps = {
  className?: string;
  stages: WorkflowManual["data"]["steps"];
  workflowId: string;
} & AriaToolbarProps;

const stepStatusOptions: { id: WorkflowStepStatus; name: string }[] = [
  { id: "initial", name: statusText("initial") },
  { id: "started", name: statusText("started") },
  { id: "skipped", name: statusText("skipped") },
  { id: "cancelled", name: statusText("cancelled") },
  { id: "finished", name: statusText("finished") },
];

export const WorkflowStages: FC<WorkflowStagesProps> = ({
  stages,
  workflowId,
  ...props
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const items = useMemo(
    () =>
      stages.map((stage, index, arr) => ({
        ...stage,
        nextStatus: index + 1 < arr.length ? stages[index + 1].status : null,
      })),
    [stages],
  );

  const { toolbarProps } = useToolbar(props, ref);

  return (
    <span
      {...toolbarProps}
      ref={ref}
      className={cn(
        "flex h-10 w-full min-w-max items-center gap-1.5 rounded px-1",
        props.className,
      )}
    >
      <Collection items={items}>
        {(item) => (
          <>
            <StageButton item={item} workflowId={workflowId} />
            <StageSeperator
              className={cn({
                "bg-gradient-to-r from-canvas-open-muted":
                  item.status === "initial" && item.nextStatus !== "initial",
                "to-canvas-open-muted": item.nextStatus === "initial",

                "bg-gradient-to-r from-canvas-closed-muted":
                  item.status === "cancelled" &&
                  item.nextStatus !== "cancelled",
                "to-canvas-closed-muted": item.nextStatus === "cancelled",

                "bg-gradient-to-r from-canvas-success-muted":
                  item.status === "finished" && item.nextStatus !== "finished",
                "to-canvas-success-muted": item.nextStatus === "finished",

                "bg-gradient-to-r from-brd":
                  item.status === "skipped" && item.nextStatus !== "skipped",
                "to-brd-muted": item.nextStatus === "skipped",

                "bg-gradient-to-r from-canvas-accent-muted":
                  item.status === "started" && item.nextStatus !== "started",
                "to-canvas-accent-muted": item.nextStatus === "started",
              })}
            />
          </>
        )}
      </Collection>
    </span>
  );
};

type SeparatorProps = Parameters<typeof useSeparator>[0] & {
  className?: string;
};

const StageSeperator: FC<SeparatorProps> = (props) => {
  const { orientation = "horizontal" } = props;
  const { separatorProps } = useSeparator(props);

  return (
    <span
      {...separatorProps}
      className={cn(
        "block flex-1 rounded-full bg-brd last:hidden",
        {
          "mx-1 max-h-12 min-h-2 w-1": orientation === "vertical",
          "my-1 h-1 min-w-2 max-w-12": orientation === "horizontal",
        },
        props.className,
      )}
    ></span>
  );
};

const StageIcon: FC<{ status: WorkflowStepStatus }> = ({ status }) => {
  switch (status) {
    case "cancelled":
      return <MinusIcon className="size-4" />;

    case "finished":
      return <CheckIcon className="size-3" strokeWidth={4} />;

    case "initial":
      return <PlusCircleIcon className="size-4" />;

    case "skipped":
      return <ChevronsRightIcon className="size-4" />;

    case "started":
      return <Clock16Filled className="size-4" />;

    default:
      return <div className="size-1 rounded-full bg-fg-muted"></div>;
  }
};

type StageButtonProps = {
  item: {
    nextStatus: WorkflowStepStatus | null;
    name: string;
    status: WorkflowStepStatus;
    seq: number;
    id: string;
  };
  workflowId: string;
};

function StageButton({ item, workflowId }: StageButtonProps) {
  const [selection, setSelection] = useState<Selection>(new Set([item.status]));

  const queryClient = useQueryClient();

  useEffect(() => {
    setSelection(new Set([item.status]));
  }, [item.status]);

  const mutation = useMutation({
    mutationFn: async (
      data: Partial<{
        steps: Record<
          string,
          {
            status: WorkflowStepStatus;
            seq: number;
          }
        >;
        comment: string | null;
      }>,
    ) => {
      await updateWorkflowData(workflowId, data, api);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workflows"] });
      queryClient.invalidateQueries({
        queryKey: ["workflow", { id: item.id }],
      });
    },
    onError: () => {
      setSelection(new Set([item.status]));
    },
  });

  return (
    <DialogTrigger>
      <TooltipTrigger delay={300}>
        <UnstyledButton
          className={cn(
            "flex size-5 items-center justify-center rounded-full bg-canvas text-fg-on-emphasis !outline-none !ring-[3px] ring-offset-0 data-[focus-visible]:!outline-brd-accent-emphasis",
            {
              "bg-canvas-open-muted text-fg-open ring-canvas-open-muted":
                item.status === "initial",

              "bg-brd-closed-emphasis ring-canvas-closed-muted":
                item.status === "cancelled",

              "bg-brd-success-emphasis ring-canvas-success-muted":
                item.status === "finished",

              "bg-brd-muted text-fg-muted ring-brd-muted":
                item.status === "skipped",

              "bg-canvas-accent-emphasis ring-canvas-accent-muted":
                item.status === "started",
            },
          )}
        >
          <StageIcon status={item.status} />
        </UnstyledButton>
        <Tooltip className="pointer-events-none">{item.name}</Tooltip>
      </TooltipTrigger>
      <Popover placement="bottom" offset={16} className="p-0">
        <div className={cn("rounded-t border-b bg-canvas-muted px-6 pt-1")}>
          <h1 className="mx-auto mb-1 w-max font-display">{item.name}</h1>
          <p className="mb-1 w-max text-sm text-fg-muted">
            Selecteer de status
          </p>
        </div>
        <ListBox
          aria-label="Selecteer de status van deze taak"
          items={stepStatusOptions}
          selectionMode="single"
          disallowEmptySelection
          selectedKeys={selection}
          onSelectionChange={onSelectionChange}
          className="space-y-1 !outline-none"
          autoFocus
        >
          {({ id, name }) => (
            <ListBoxItem
              id={id}
              className={cn(
                "!text-sm data-[focus-visible]:bg-canvas-emphasis/20 data-[hovered]:bg-canvas-muted data-[hovered]:data-[selected]:bg-canvas-emphasis/80 data-[selected]:bg-canvas-emphasis data-[focus-visible]:text-fg data-[hovered]:ring-1 data-[hovered]:ring-brd",
              )}
            >
              {name}
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </DialogTrigger>
  );

  function onSelectionChange(keys: Selection) {
    if (keys !== "all") {
      setSelection(keys);

      const newStatus = Array.from(keys)[0] as WorkflowStepStatus;
      mutation.mutate({
        steps: {
          [item.name]: {
            seq: item.seq,
            status: newStatus,
          },
        },
      });
    }
  }
}

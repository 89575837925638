import { lazy, type ReactNode } from "react";

import {
  createRouteMask,
  createRouter,
  DefaultGlobalNotFound,
  ErrorComponent,
  Navigate,
  parseSearchWith,
  ScrollRestoration,
  stringifySearchWith,
  ToPathOption,
  useRouter,
  type ErrorComponentProps,
  type ErrorRouteComponent,
  type NavigateOptions,
  type RegisteredRouter,
  type ToOptions,
} from "@tanstack/react-router";
import { parse, stringify } from "jsurl2";
import { RouterProvider } from "react-aria-components";
import type { RouteLocationNormalized } from "vue-router";

import { isHTTPError } from "@dokworks/fetch";
import { isNumeric } from "@dokworks/shared";
import { Base, Button } from "@dokworks/ui";

import { useAuth } from "@/auth";
import { routeTree } from "@/routeTree.gen";
import { queryClient } from "@/utils/queryClient";
import { FullPageLoader } from "@/components/fullPageLoader";

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : lazy(() =>
      // Lazy load in development
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    );

const ReactQueryDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : lazy(() =>
      // Lazy load in development
      import("@tanstack/react-query-devtools").then((res) => ({
        default: res.ReactQueryDevtools,
      })),
    );

function InnerWrap({ children }: { children: ReactNode }) {
  const router = useRouter();

  return (
    <>
      <ScrollRestoration getKey={(location) => location.pathname} />

      <RouterProvider
        navigate={(to, options) => {
          if (to.startsWith("tel:")) {
            const link = document.createElement("a");
            link.href = to;
            link.click();
            return;
          }

          router.navigate({ to, ...options });
        }}
        useHref={(to) => router.buildLocation({ to }).href}
      >
        {children}
      </RouterProvider>

      <ReactQueryDevtools buttonPosition="top-left" position="left" />
      <TanStackRouterDevtools position="bottom-left" />
    </>
  );
}

const DefaultErrorComponent: ErrorRouteComponent = ({
  error,
  reset,
}: ErrorComponentProps) => {
  const router = useRouter();
  const auth = useAuth();

  if (isHTTPError(error)) {
    return (
      <div className="border border-brd-severe-emphasis bg-canvas-severe-muted px-3 py-2 text-fg-severe">
        {error.message}
        <div className="flex items-center gap-2">
          <Button
            variant="severe"
            onPress={() => {
              // Reset the router error boundary
              reset();
              // Invalidate the route to reload the loader
              router.invalidate();
            }}
          >
            retry
          </Button>
          <Button
            variant="danger"
            onPress={() => {
              auth.logout().then(() => {
                router.invalidate().then(() => {
                  router.navigate({ to: "/login" });
                });
              });
            }}
          >
            Logout
          </Button>
        </div>
      </div>
    );
  }

  return <ErrorComponent error={error} />;
};

// Set up a Router instance
const router = createRouter({
  routeTree,
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
    queryClient,
  },

  parseSearch: parseSearchWith(parse),
  stringifySearch: stringifySearchWith(stringify),

  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  defaultErrorComponent: DefaultErrorComponent,
  defaultNotFoundComponent: () => <Navigate to="/dashboard" replace />,
  defaultPendingComponent: () => (
    <Base noBlur colorMode="light">
      <FullPageLoader withLogo className="fixed inset-0 bg-canvas" />
    </Base>
  ),

  InnerWrap,
});

type Router = typeof router;

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: Router;
  }
}

declare module "react-aria-components" {
  interface RouterConfig {
    href: ToPathOption<RegisteredRouter>;
    routerOptions: Omit<NavigateOptions, keyof ToOptions>;
  }
}

window.vueNav = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) => {
  const toName = to.name?.toString().toLowerCase();
  const fromName = from.name?.toString().toLowerCase();
  const replace = toName === fromName;

  switch (to.name?.toString().toLowerCase()) {
    case "dossiers":
      router.navigate({ to: "/dashboard", replace, mask: "/" });
      break;

    case "dossieredit":
      router.navigate({
        to: "/dossier/$dossierId/edit",
        params: {
          dossierId: to.params.dossierId.toString(),
        },
        replace,
        search: {
          ...router.latestLocation.search,
          page: isNumeric(to.query["page"]?.toString())
            ? Number(to.query["page"]!.toString())
            : 0,
          section: isNumeric(to.query["section"]?.toString())
            ? Number(to.query["section"]!.toString())
            : 0,
        },
      });
      break;

    case "dossierpdfviewer":
      router.navigate({
        to: "/dossier/$dossierId/pdf/$dossierActionId",
        params: {
          dossierId: to.params.dossierId.toString(),
          dossierActionId: to.params.dossierActionId.toString(),
        },
        replace,
      });
      break;

    case "dossierdocument":
      router.navigate({
        to: "/dossier/$dossierId/document/$documentId",
        params: {
          dossierId: to.params.dossierId.toString(),
          documentId: to.params.documentId.toString(),
        },
        replace,
      });
      break;

    case "userself":
      router.navigate({
        to: "/settings",
        replace,
      });
      break;

    default:
      break;
  }
};

export { router, type Router };

import { useState, type FormEvent } from "react";

import { createFileRoute } from "@tanstack/react-router";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { Form, Input } from "react-aria-components";

import { isHTTPError } from "@dokworks/fetch";
import { confirmPasswordReset } from "@dokworks/fetch/auth";
import {
  Button,
  Spinner,
  styledButton,
  TextField,
  ToggleButton,
  UnstyledLink,
} from "@dokworks/ui";

import { api } from "@/utils/fetch/api";

export const Route = createFileRoute(
  "/login/resetPassword/confirm/$uid/$token",
)({
  component: ResetPasswordConfirmComponent,
});

function ResetPasswordConfirmComponent() {
  const { uid, token } = Route.useParams();
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [newPasswordConfirmType, setNewPasswordConfirmType] =
    useState("password");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState("");
  const [submitError, setSubmitError] = useState("");

  return (
    <>
      {response && (
        <p className="space-y-1 rounded-md border border-brd-success-emphasis bg-canvas-success-muted px-4 py-2 text-center text-sm text-fg-success">
          {response}
        </p>
      )}
      <Form className="flex flex-col pt-12" onSubmit={onFormSubmit}>
        <fieldset
          className="flex flex-1 flex-col gap-4"
          disabled={isSubmitting}
        >
          <TextField
            label="Nieuw wachtwoord *"
            isRequired
            type={newPasswordType}
            autoComplete="new-password"
          >
            <Input
              name="new-password"
              placeholder="********"
              autoComplete="new-password"
              className="w-full"
            />
            <Button
              variant="ghost"
              size="icon-small"
              className="my-auto -mr-2 text-fg-accent"
              onPress={() => {
                setNewPasswordType((type) =>
                  type === "password" ? "text" : "password",
                );
              }}
            >
              {newPasswordType === "password" ? (
                <EyeOffIcon className="size-4" />
              ) : (
                <EyeIcon className="size-4" />
              )}
            </Button>
          </TextField>
          <TextField
            label="Wachtwoord bevestigen *"
            isRequired
            type={newPasswordConfirmType}
            autoComplete="new-password"
          >
            <Input
              name="confirm-new-password"
              placeholder="********"
              autoComplete="new-password"
              className="w-full"
            />
            <Button
              variant="ghost"
              size="icon-small"
              className="my-auto -mr-2 text-fg-accent"
              onPress={() => {
                setNewPasswordConfirmType((type) =>
                  type === "password" ? "text" : "password",
                );
              }}
            >
              {newPasswordConfirmType === "password" ? (
                <EyeOffIcon className="size-4" />
              ) : (
                <EyeIcon className="size-4" />
              )}
            </Button>
          </TextField>
          {submitError && (
            <p className="text-sm text-fg-danger">{submitError}</p>
          )}
          <span className="mt-2 flex gap-2">
            <UnstyledLink
              className={(renderProps) =>
                styledButton({
                  ...renderProps,
                  variant: "ghost",
                  className: "text-fg-link",
                  size: "large",
                })
              }
              href="/login"
            >
              Ga terug
            </UnstyledLink>
            <Button
              type="submit"
              variant="accent"
              size="large"
              className="flex-1"
              onPress={() => {
                setSubmitError("");
                setResponse("");
              }}
            >
              {isSubmitting ? (
                <>
                  <Spinner /> Loading...
                </>
              ) : (
                "Update wachtwoord"
              )}
            </Button>
          </span>
        </fieldset>
      </Form>
    </>
  );

  async function onFormSubmit(ev: FormEvent<HTMLFormElement>) {
    setSubmitError("");
    setResponse("");

    try {
      ev.preventDefault();
      const form = ev.currentTarget;
      const data = new FormData(form);
      const newPassword = data.get("new-password")?.toString() ?? "";
      const newPasswordConfirm =
        data.get("confirm-new-password")?.toString() ?? "";

      if (!newPassword || !newPasswordConfirm) return;

      if (newPassword !== newPasswordConfirm) {
        setSubmitError("Wachtwoorden komen niet overeen.");
        return;
      }

      setIsSubmitting(true);
      await confirmPasswordReset(
        newPassword,
        newPasswordConfirm,
        uid,
        token,
        api,
      );

      setResponse("Wachtwoord is geupdate!");
    } catch (error) {
      if (isHTTPError(error)) {
        setSubmitError(
          "Er is een fout opgetreden bij het updaten van uw wachtwoord. Probeer het later opnieuw.",
        );
      }
      console.error(error);
    }

    setIsSubmitting(false);
  }
}

import type { FC } from "react";

import { useMatchRoute } from "@tanstack/react-router";
import { FolderIcon, FolderOutputIcon } from "lucide-react";
import { Tab, TabList } from "react-aria-components";

import { cn } from "@dokworks/shared";
import { styledToggleButton } from "@dokworks/ui";

export const NavigationBar: FC = () => {
  const matchRoute = useMatchRoute();

  const inDossier = !!matchRoute({ fuzzy: true, to: "/dossier/$dossierId" });

  return (
    <TabList
      className="flex flex-col flex-nowrap gap-1 pl-2 pr-1"
      aria-label="Pagina's"
    >
      <Tab
        id="/dashboard"
        href="/dashboard"
        aria-label="Dashboard link"
        className={(renderProps) => {
          return styledToggleButton({
            ...renderProps,
            variant: "floating",
            size: "icon-medium",
            isDisabled: !inDossier,
            isSelected: inDossier,
            className: cn(
              inDossier
                ? "rounded-r-sm transition-all ease-productive"
                : "data-[hovered]:bg-transparent data-[hovered]:text-fg-disabled",
            ),
          });
        }}
      >
        {(renderProps) => {
          if (!inDossier || !renderProps.isHovered)
            return <FolderIcon className="size-6" />;

          return <FolderOutputIcon className="size-6" />;
        }}
      </Tab>
      {/* <Tab
        id="/logs"
        href="/logs"
        aria-label="Audit logs link"
        className={(renderProps) =>
          styledToggleButton({
            ...renderProps,
            variant: "floating",
            size: "icon-medium",
            className: cn(
              renderProps.isSelected &&
                "duration-110 rounded-r-sm transition-all ease-productive",
            ),
          })
        }
      >
        <NotebookTextIcon className="size-5" />
      </Tab> */}
    </TabList>
  );
};

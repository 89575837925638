import type { FC } from "react";

import { cn, WorkflowType, type Workflow } from "@dokworks/shared";

import { statusText } from "@/utils/helpers/workflowHelper";

interface StatusPlateProps {
  workflow: Workflow;
}

export const StatusPlate: FC<StatusPlateProps> = ({ workflow }) => {
  if (workflow.type === WorkflowType.AUTO) {
    return (
      <span
        className={cn(
          "inline-block rounded border px-2 py-1 text-xs font-semibold lowercase leading-none tracking-wider text-fg-on-emphasis shadow-sm",
          {
            "border-brd-accent-emphasis/20 bg-canvas-accent-muted text-fg-accent":
              workflow.data.status === "open",
            "border-brd-closed bg-canvas-closed-muted text-fg-closed":
              workflow.data.status === "cancelled",
            "border-brd-attention bg-canvas-attention-muted text-fg-attention":
              workflow.data.status === "waiting",
            "border-brd-success bg-canvas-success-muted text-fg-success":
              workflow.data.status === "closed",
          },
        )}
      >
        {statusText(workflow.data.status)}
      </span>
    );
  } else {
    const stepList = Object.values(workflow.data.steps);

    const isCancelled = stepList.some(({ status }) => status === "cancelled");

    if (isCancelled) {
      return (
        <span className="inline-block rounded border border-brd-danger bg-canvas-danger-muted px-2 py-1 text-xs lowercase leading-none tracking-wider text-fg-danger shadow-sm">
          {statusText("cancelled")}
        </span>
      );
    }

    const isFinished = stepList.every(
      ({ status }) => status === "finished" || status === "skipped",
    );

    if (isFinished) {
      return (
        <span className="inline-block rounded border border-brd-success bg-canvas-success-muted px-2 py-1 text-xs lowercase leading-none tracking-wider text-fg-success shadow-sm">
          {statusText("finished")}
        </span>
      );
    }

    const isInitial = !stepList.some(({ status }) => status !== "initial");

    if (isInitial) {
      return (
        <span className="inline-block rounded border border-brd-open-emphasis/20 bg-canvas-open-muted px-2 py-1 text-xs font-semibold lowercase leading-none tracking-wider text-fg-open shadow-sm">
          {statusText("initial")}
        </span>
      );
    }
    return (
      <span className="inline-block rounded border border-brd-accent-emphasis/20 bg-canvas-accent-muted px-2 py-1 text-xs font-semibold lowercase leading-none tracking-wider text-fg-accent shadow-sm">
        {statusText("started")}
      </span>
    );
  }
};

import type { WorkflowStatus, WorkflowStepStatus } from "@dokworks/shared";

export const statusText = (
  status: WorkflowStepStatus | WorkflowStatus,
): string => {
  switch (status) {
    case "initial":
      return "Nieuw";

    case "cancelled":
      return "Afgebroken";

    case "closed":
    case "finished":
      return "Klaar";

    case "skipped":
      return "Skipped";

    case "open":
    case "started":
      return "Gestart";

    case "waiting":
      return "In behandeling";

    default:
      return "Onbekend";
  }
};

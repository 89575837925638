import { useQuery } from "@tanstack/react-query";
import {
  createRootRouteWithContext,
  Outlet,
  useMatchRoute,
  useRouterState,
} from "@tanstack/react-router";

import { cn, preloadImage } from "@dokworks/shared";
import { Base } from "@dokworks/ui";

import { useAuth } from "@/auth";
import type { AppContext } from "@/types";
import { userBackgroundQueryOptions } from "@/utils/queryOptions/user.queryOptions";

export const Route = createRootRouteWithContext<AppContext>()({
  component: RootComponent,
  loader: async ({ context }) => {
    const backgroundUrl = await context.queryClient.ensureQueryData({
      ...userBackgroundQueryOptions(context.auth.isAuthenticated),
    });

    await preloadImage(backgroundUrl);
  },
});

function RootComponent() {
  const matchRoute = useMatchRoute();

  const isLoading = useRouterState({
    select: (state) =>
      state.isLoading || state.isTransitioning || state.status === "pending",
  });

  const auth = useAuth();

  const { data: backgroundUrl } = useQuery(
    userBackgroundQueryOptions(auth.isAuthenticated),
  );

  return (
    <Base
      src={backgroundUrl}
      className={cn(isLoading && "cursor-progress")}
      colorMode="light"
      noBlur={!!matchRoute({ to: "/login", fuzzy: true })}
    >
      <Outlet />
    </Base>
  );
}

import { createFileRoute } from "@tanstack/react-router";

import { DossiersLog } from "@/routes/_auth/-components/dossiersLog";

export const Route = createFileRoute("/_auth/dossier/$dossierId/logs")({
  component: LogsComponent,
});

function LogsComponent() {
  return (
    <div className="floating flex flex-1 flex-col gap-4 rounded-tl-sm px-6">
      <h1 className="mt-8 font-display text-3xl font-semibold">Audit log</h1>
      <div className="mt-2 rounded-md border border bg-canvas pt-4 shadow-sm">
        <DossiersLog className="rounded-md" />
      </div>
    </div>
  );
}

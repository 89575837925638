import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from "@tanstack/react-query";
import { z } from "zod";

import type { HTTPError } from "@dokworks/fetch";
import { fetchDossier } from "@dokworks/fetch/dossier";
import { isNumeric, type DataWithPagination } from "@dokworks/shared";

import { api } from "@/utils/fetch/api";
import { mergeParams, type MimeDB, type Paginated } from "@/utils/fetch/common";
import { logListSchema } from "@/utils/fetch/log";
import { workflowListSchema } from "@/utils/fetch/workflow";

const dossierSearchSchema = z.object({
  workflowFilter: workflowListSchema.optional().catch(undefined),
  logFilter: logListSchema.optional().catch(undefined),
});

type DossierLayoutSearch = z.infer<typeof dossierSearchSchema>;

interface DossierFileDetail {
  name: string;
  file: string;
  workflowFileType: string;
  fileType: string;
  created: string;
}
export interface DossierFile {
  workflowRef: string;
  files: DossierFileDetail[];
}

const dossierQueryOptions = (dossierId: string) =>
  queryOptions({
    queryKey: ["dossier", { id: dossierId }],
    queryFn: async ({ signal }) => {
      const dossier = await fetchDossier(dossierId, api, { signal });

      return dossier;
    },
  });

const dossierFilesQueryOptions = (
  opts: {
    dossierId: string;
    workflowId?: string;
  } & Paginated,
  mimeDb: MimeDB,
) =>
  infiniteQueryOptions<DataWithPagination<DossierFile>, HTTPError>({
    queryKey: ["dossier", "files", opts],
    staleTime: 30_000,
    placeholderData: keepPreviousData,

    getPreviousPageParam: (firstPage) => firstPage?.previous ?? null,
    getNextPageParam: (lastPage) => lastPage?.next ?? null,
    initialPageParam: `?limit=${opts.limit}&offset=${opts.offset}`,

    queryFn: async ({ signal, pageParam }) => {
      const data = await api
        .get("workflow_v2/dossier/" + opts.dossierId + "/files/", {
          searchParams: mergeParams(
            opts,
            typeof pageParam === "string" ? pageParam : null,
          ),
          signal,
        })
        .json<DataWithPagination<DossierFile>>();

      return data;
    },
  });

const dossierFileHeadQueryOptions = (opts: {
  workflowId: string;
  fileId: string;
}) =>
  queryOptions<
    {
      fileName: string | null;
      mimeType: string | null;
      size: number;
    },
    HTTPError
  >({
    queryKey: ["dossier", "file", opts.fileId],

    queryFn: async ({ signal }) => {
      const { headers } = await api.head(
        `workflow_v2/file/${opts.workflowId}/${opts.fileId}/`,
        {
          signal,
        },
      );

      let fileName = headers.get("Content-Disposition");
      if (fileName) {
        fileName = fileName.substring(
          fileName.indexOf('filename="'),
          fileName.lastIndexOf('"'),
        );
      }

      let size: string | number | null = headers.get("Content-Length");
      if (size && isNumeric(size)) {
        size = Number(size);
      } else {
        size = 0;
      }
      const mimeType = headers.get("Content-Type");

      return {
        fileName,
        mimeType,
        size,
      };
    },
  });

const dossierDocumentListQueryOptions = (dossierId: string) =>
  queryOptions({
    queryKey: ["dossier", "documents", { id: dossierId }],
    queryFn: async ({ signal }) => {
      const res = await api.get(`documents/${dossierId}`);
      const paginatedData = await res.json<
        DataWithPagination<{
          id: string;
          name: string;
          stale: boolean;
        }>
      >();

      return paginatedData;
    },
  });

const dossierDocumentQueryOptions = (documentId: string) =>
  queryOptions({
    queryKey: ["dossier", "document", { id: documentId }],

    queryFn: async ({ signal }) => {
      const res = await api.get(`document/${documentId}`, { signal });
      const document = await res.json<{
        id: string;
        name: string;
        stale: boolean;
      }>();

      return document;
    },
  });

const createDossierDocumentQueryOptions = (dossierId: string) =>
  queryOptions({
    queryKey: ["dossier", "documents", { id: dossierId }],
    queryFn: async ({ signal }) => {
      const res = await api.post("document/create", {
        json: { dossier_id: dossierId },
        signal,
      });

      const { documentId } = await res.json<{ documentId: string }>();

      const document = await api
        .get(`document/${documentId}`, { signal })
        .json<{ id: string; name: string; stale: boolean }>();

      return document;
    },
  });

export {
  dossierSearchSchema,
  type DossierLayoutSearch,
  dossierQueryOptions,
  dossierFilesQueryOptions,
  dossierDocumentListQueryOptions,
  dossierDocumentQueryOptions,
  createDossierDocumentQueryOptions,
};

import { type FC } from "react";

import { useDateFormatter, type DateFormatterOptions } from "react-aria";

import type { WorkflowManual } from "@dokworks/shared";
import { Cell, Column, Row, Table, TableBody, TableHeader } from "@dokworks/ui";

import { statusText } from "@/utils/helpers/workflowHelper";
import { WorkflowStages } from "@/components/workflow/stages.workflow";
import { StatusPlate } from "@/components/workflow/statusPlate.workflow";

type WorkflowManualTableProps = {
  items: WorkflowManual[];
};

const options: DateFormatterOptions = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

export const WorkflowManualTable: FC<WorkflowManualTableProps> = ({
  items,
}) => {
  const dateFormatter = useDateFormatter(options);

  return (
    <Table aria-label="Manual Workflows" className="h-[560px] rounded-md">
      <TableHeader>
        <Column isRowHeader textValue="Naam" defaultWidth={360}>
          Descriptie
        </Column>
        <Column textValue="Status" maxWidth={140}>
          Status
        </Column>
        <Column textValue="Steps">Taken</Column>
        <Column textValue="Aangemaakt op" width={240}>
          Aangemaakt op
        </Column>
        <Column textValue="Laatst aangepast op" width={240}>
          Laatst aangepast op
        </Column>
      </TableHeader>
      <TableBody
        items={items}
        renderEmptyState={() => (
          <p className="p-2 text-center">Geen handmatige workflows gevonden.</p>
        )}
      >
        {function renderRow(item) {
          const createdAt = dateFormatter.format(new Date(item.createdAt));
          const updatedAt = dateFormatter.format(new Date(item.updatedAt));
          return (
            <Row id={item.id}>
              <Cell textValue={item.name}>{item.name}</Cell>
              <Cell
                textValue={item.data.steps
                  .map((step) => statusText(step.status))
                  .join(", ")}
              >
                {<StatusPlate workflow={item} />}
              </Cell>
              <Cell textValue={Object.keys(item.data.steps).join(", ")}>
                <WorkflowStages stages={item.data.steps} workflowId={item.id} />
              </Cell>
              <Cell textValue={updatedAt}>{updatedAt}</Cell>
              <Cell textValue={createdAt}>{createdAt}</Cell>
            </Row>
          );
        }}
      </TableBody>
    </Table>
  );
};

import { ComponentProps, FC } from "react";

export const Icon: FC<ComponentProps<"svg">> = ({
  width = 32,
  height = 32,
  ...props
}) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 54 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: "2",
      ...props.style,
    }}
  >
    <path
      d="M52.962,24.309c-0.404,-2.25 -1.068,-4.5 -1.991,-6.758c-0.923,-2.242 -2.178,-4.442 -3.764,-6.576c-1.587,-2.135 -3.577,-4.017 -5.971,-5.669c-2.395,-1.637 -5.222,-2.949 -8.481,-3.937c-0.923,-0.274 -1.976,-0.419 -2.979,-0.62l0,8.336c1.061,0.339 2.207,0.591 3.109,1.067c3.115,1.652 5.552,3.657 7.312,6.015c1.76,2.365 2.957,4.868 3.599,7.485c0.627,2.625 0.944,4.926 0.944,6.88c0,3.815 -0.692,7.147 -2.077,10.003c-1.384,2.855 -3.259,5.235 -5.625,7.132c-2.365,1.911 -5.12,3.332 -8.264,4.291c-3.144,0.952 -6.476,1.42 -9.995,1.42l-10.471,0l-0,-24.49l-8.308,-0l-0,32.279l19.99,-0c5.481,-0 10.313,-0.866 14.496,-2.589c4.182,-1.731 7.687,-4.031 10.514,-6.88c2.827,-2.863 4.962,-6.123 6.404,-9.786c1.442,-3.656 2.163,-7.457 2.163,-11.38c0,-1.896 -0.202,-3.973 -0.605,-6.223"
      style={{ fill: "currentcolor" }}
    />
    <path
      d="M22.601,22.601l-22.601,-0l0,-6.31l16.212,-16.291l6.389,0l-0,22.601Z"
      className="fill-fg-accent"
    />
  </svg>
);

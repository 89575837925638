import { createContext, useCallback, useContext } from "react";

import { useActorRef, useSelector } from "@xstate/react";
import { Actor, fromPromise } from "xstate";

import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "@dokworks/fetch";
import { refreshAccessToken } from "@dokworks/fetch/auth";
import {
  authMachine,
  RefreshLogicInput,
  type AuthMachine,
} from "@dokworks/logic/auth";
import { TokenData, User, useSafeTimeout } from "@dokworks/shared";
import { Spinner } from "@dokworks/ui";

import { api } from "@/utils/fetch/api";

export interface AuthContext {
  isAuthenticated: boolean;
  user?: User | null;
  send: Actor<AuthMachine>["send"];
  login: (data: TokenData) => Promise<void>;
  logout: () => Promise<void>;
}

const refreshLogic = fromPromise<string, RefreshLogicInput>(
  async ({ input, signal }) => {
    const { access } = await refreshAccessToken(input.getRefreshToken(), api, {
      signal,
    });

    return access;
  },
);

const AuthContext = createContext<AuthContext | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const actorRef = useActorRef(
    authMachine.provide({
      actors: {
        refreshLogic,
      },
    }),
    {
      input: {
        getAccessToken: () => getAccessToken(localStorage),
        setAccessToken: (token) => setAccessToken(token, localStorage),
        getRefreshToken: () => getRefreshToken(localStorage),
        setRefreshToken: (token) => setRefreshToken(token, localStorage),
      },
    },
  );

  const isStillLoading = useSelector(actorRef, (snapshot) =>
    snapshot.matches("checkingIfLoggedIn"),
  );

  const isAuthenticated = useSelector(actorRef, (snapshot) =>
    snapshot.matches("loggedIn"),
  );

  const { safeClearTimeout, safeSetTimeout } = useSafeTimeout();

  const send = useCallback<Actor<AuthMachine>["send"]>(
    (event) => actorRef.send(event),
    [actorRef],
  );

  const login = useCallback(
    ({ access, refresh }: TokenData) =>
      new Promise<void>((resolve, reject) => {
        const id = safeSetTimeout(() => reject("TIMEOUT"), 3000);
        const subscription = actorRef.subscribe((snapshot) => {
          if (snapshot.matches("loggedIn")) {
            safeClearTimeout(id);
            subscription.unsubscribe();
            resolve();
          }
        });

        actorRef.send({ type: "LOG_IN_SUCCESS", token: access, refresh });
      }),
    [actorRef, safeClearTimeout, safeSetTimeout],
  );

  const logout = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        const id = safeSetTimeout(() => reject("TIMEOUT"), 3000);
        const subscription = actorRef.subscribe((snapshot) => {
          if (snapshot.matches("loggedOut")) {
            safeClearTimeout(id);
            subscription.unsubscribe();
            resolve();
          }
        });

        actorRef.send({ type: "LOG_OUT" });
      }),
    [actorRef, safeClearTimeout, safeSetTimeout],
  );

  if (isStillLoading)
    return (
      <div className="h-stretch w-full">
        <Spinner />
      </div>
    );

  return (
    <AuthContext.Provider value={{ isAuthenticated, send, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  beforeLoad: ({ context: { auth } }) => {
    throw redirect({
      to: auth.isAuthenticated ? "/dashboard" : "/login",
      replace: true,
    });
  },
});

import { useState, type FormEvent } from "react";

import {
  createFileRoute,
  useRouter,
  useRouterState,
} from "@tanstack/react-router";
import { Form } from "react-aria-components";
import { z } from "zod";

import { tryLogin } from "@dokworks/fetch/auth";
import { Button, Input, Link, Spinner, TextField } from "@dokworks/ui";

import { useAuth } from "@/auth";
import { fallback } from "@/routes/login/-shared";
import { api } from "@/utils/fetch/api";

export const Route = createFileRoute("/login/")({
  component: LoginIndexComponent,
});

function LoginIndexComponent() {
  const auth = useAuth();
  const router = useRouter();
  const queryClient = Route.useRouteContext({
    select: (ctx) => ctx.queryClient,
  });
  const navigate = Route.useNavigate();

  const search = Route.useSearch();
  const isLoading = useRouterState({ select: (s) => s.isLoading });

  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isLoggingIn = isLoading || isSubmitting;

  const onFormSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    setError("");

    evt.preventDefault();

    try {
      const data = Object.fromEntries(new FormData(evt.currentTarget));
      const username = data.username.toString();
      const password = data.password.toString();

      if (!username || !password) return;

      const res = await tryLogin(username, password, api);

      if ("access" in res) {
        await auth.login(res);
        await router.invalidate();
        await queryClient.invalidateQueries({ queryKey: ["user"] });
        await navigate({ to: search.redirect || fallback });
      } else {
        // TODO implement two factor authentication
        throw Error("Two factor authentication is not currently supported.");
      }
    } catch (error) {
      console.error(error);
      setError("Ongeldige inloggegevens.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form className="flex flex-col pt-12" onSubmit={onFormSubmit}>
      <fieldset className="flex flex-1 flex-col gap-4" disabled={isLoggingIn}>
        <TextField label="Gebruikersnaam" isRequired>
          <Input
            id="username-input"
            name="username"
            placeholder="E.g., naam@domein.nl"
            type="text"
            autoComplete="username"
            className="w-full"
          />
        </TextField>
        <span>
          <TextField label="Wachtwoord" isRequired>
            <Input
              id="password-input"
              name="password"
              placeholder="********"
              autoComplete="current-password"
              type="password"
              className="w-full"
            />
          </TextField>
          <p className="mt-1 text-xs">
            <Link href="resetpassword">Wachtwoord vergeten?</Link>
          </p>
        </span>

        {error && <p className="text-sm text-fg-danger">{error}</p>}
        <Button type="submit" variant="accent" size="large" className="mt-2">
          {isLoggingIn ? (
            <>
              <Spinner /> Loading...
            </>
          ) : (
            "Login"
          )}
        </Button>
      </fieldset>
    </Form>
  );
}

import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { z } from "zod";

import { HTTPError } from "@dokworks/fetch";
import { fetchWorkflows } from "@dokworks/fetch/workflow";
import {
  toCamelCase,
  WorkflowType,
  type Workflow,
  type WorkflowFetchData,
  type WorkflowManualStep,
} from "@dokworks/shared";

import { api } from "@/utils/fetch/api";
import { paginationSchema } from "@/utils/fetch/common";

const workflowListSchema = paginationSchema.extend({
  dossierId: z.string().optional().catch(undefined),
  status: z
    .enum(["open", "waiting", "closed", "cancelled"])
    .optional()
    .catch(undefined),
  wtype: z.string().optional().catch(undefined),
  type: z.nativeEnum(WorkflowType).optional().catch(undefined),
});

type WorkflowListFilter = z.infer<typeof workflowListSchema>;

function workflowDataToWorkflow(data: WorkflowFetchData): Workflow {
  const workflow: Workflow = data as unknown as Workflow;
  if (data.type === WorkflowType.MANUAL) {
    workflow.data.steps = Object.keys(data.data.steps).map((key) => {
      const step = data.data.steps[key];

      return {
        id: `workflow-step:${key}:${step.seq}`,
        name: key,
        ...step,
      } satisfies WorkflowManualStep;
    });

    workflow.data.steps.sort((a, b) => a.seq - b.seq);
  }

  return workflow;
}

const singleWorkflowQueryOptions = (id: string, dossierId?: string) =>
  queryOptions({
    queryKey: ["workflow", { id, ...(dossierId ? { dossierId } : {}) }],
    queryFn: async ({ signal }): Promise<Workflow> => {
      // TODO rewrite this to use the single workflow endpoint
      const data: Workflow = await fetchWorkflows(api, {
        signal,
        searchParams: dossierId
          ? {
              dossier_id: dossierId,
            }
          : {},

        parseJson: (text) => JSON.parse(text),

        hooks: {
          afterResponse: [
            async (request, options, response) => {
              if (response.headers.get("Content-Type") === "application/json") {
                let workflows: WorkflowFetchData[] = [];

                const workflowError = (): HTTPError =>
                  new HTTPError(
                    new Response(null, {
                      headers: response.headers,
                      status: 404,
                      statusText: "404 Not Found",
                    }),
                    request,
                    options,
                  );
                try {
                  workflows = (await response.json()) as WorkflowFetchData[];
                } catch (err) {
                  console.error(err);

                  throw workflowError();
                }

                const index = workflows.findIndex((w) => w.reference === id);

                if (!~~index) {
                  throw workflowError();
                }

                workflows[index] = workflowDataToWorkflow({
                  ...toCamelCase(workflows[index]),
                  data: {
                    ...toCamelCase(workflows[index]),
                    statusDetail: workflows[index].data.statusDetail,
                    steps: workflows[index].data.steps,
                  },
                }) as unknown as WorkflowFetchData;

                return new Response(JSON.stringify(workflows), response);
              }
              return;
            },
          ],
        },
      }).then((workflows) => workflows.find((w) => w.id === id)!);

      return data;
    },
  });

const workflowListQueryOptions = (opts?: Partial<WorkflowListFilter>) => {
  const params = new URLSearchParams();

  if (opts) {
    if (opts.dossierId) params.append("dossier_id", opts.dossierId);
    if (opts.status) params.append("status", opts.status);
    if (opts.limit) params.append("limit", String(opts.limit || 30));
    if (opts.offset) params.append("limit", String(opts.offset || 0));
    if (opts.wtype) params.append("wtype", opts.wtype);
    if (opts.type)
      params.append(
        "type",
        opts.type === WorkflowType.AUTO ? "auto" : opts.type,
      );
  }

  return queryOptions({
    queryKey: ["workflows", ...(opts ? [opts] : [])],
    placeholderData: keepPreviousData,
    queryFn: async ({ signal }): Promise<Workflow[]> => {
      const data = await fetchWorkflows(api, {
        signal,
        searchParams: params,

        parseJson: (text) => JSON.parse(text),
        hooks: {
          afterResponse: [
            async (request, options, response) => {
              if (response.headers.get("Content-Type") === "application/json") {
                try {
                  const workflowDataList: WorkflowFetchData[] =
                    (await response.json()) as WorkflowFetchData[];

                  const workflows = workflowDataList.map((data) =>
                    workflowDataToWorkflow({
                      ...toCamelCase(data),
                      data: {
                        ...toCamelCase(data.data),

                        statusDetail:
                          "status_detail" in data.data
                            ? data.data["status_detail"]
                            : {},
                        steps: data.data.steps,
                      },
                    }),
                  );

                  return new Response(JSON.stringify(workflows), response);
                } catch (err) {
                  console.error(err);

                  throw new HTTPError(
                    new Response(null, {
                      headers: response.headers,
                      status: 404,
                      statusText: "404 Not Found",
                    }),
                    request,
                    options,
                  );
                }
              }

              return;
            },
          ],
        },
      });

      return data;
    },
  });
};

export {
  singleWorkflowQueryOptions,
  workflowListQueryOptions,
  workflowListSchema,
  type WorkflowListFilter,
};

import { parseAbsoluteToLocal } from "@internationalized/date";
import { infiniteQueryOptions, keepPreviousData } from "@tanstack/react-query";
import type { SortDescriptor } from "react-stately";
import { z } from "zod";

import type { HTTPError } from "@dokworks/fetch";
import type {
  DataWithPagination,
  Merge,
  Model,
  Organisation,
  User,
} from "@dokworks/shared";

import { api } from "@/utils/fetch/api";
import {
  mergeParams,
  paginationSchema,
  sortBy,
  type Paginated,
  type SortMode,
} from "@/utils/fetch/common";

export enum AuditSubject {
  Authentication = "auth.User",
  User = "user.UserProfile",

  Template = "template.Template",

  Dossier = "contract.Contract",
  DossierDocument = "document.Document",

  Workflow = "workflow_v2.Workflow",
  WorkflowFile = "workflow_v2.WorkflowFile",
  WorkflowUIEnv = "workflow_ui.WorkflowUIEnvironment",
  WorkflowUserAuthentication = "workflow_v2.WorkflowUserCredentials",

  WimWorkflow = "workflow.Workflow",
  WimWorkflowAction = "workflow.Action",
  WimWorkflowCondition = "workflow.Condition",
  WimWorkflowHook = "workflow.Hook",
  WimWorkflowEOConfig = "workflow.ExactOnlineConfiguration",
  WimWorkflowRedirect = "workflow.Redirect",
  WimWorkflowStage = "workflow.Stage",
  WimWorkflowHistoryTransition = "workflow.HistoryTransition",
  WimWorkflowTransition = "workflow.Transition",
  WimDashboardDossier = "wim_dashboard.WimContract",
  WimDashboardInvoice = "wim_dashboard.Invoice",
  WimDashboardXSConnectRecord = "wim_dashboard.XSConnectRecord",
}

export type AuditResponseSubject =
  | "User"
  | "UserProfile"
  | "Template"
  | "Dossier"
  | "Document"
  | "Workflow"
  | "WorkflowFile"
  | "WorkflowUIEnvironment"
  | "WorkflowUserCredentials"
  | "Action"
  | "Condition"
  | "Hook"
  | "ExactOnlineConfiguration"
  | "Redirect"
  | "Stage"
  | "HistoryTransition"
  | "Transition"
  | "WimContract"
  | "Invoice"
  | "XSConnectRecord"
  | "Block";

interface AuditLog extends Model {
  action: "Create" | "Update" | "Delete";
  actionDetail: string;

  initiatorType: "User" | "Webhook";
  initiator: Merge<
    Pick<User, "fullName" | "email">,
    {
      organisation: Pick<Organisation, "name">;
    }
  > | null;

  originType: "API" | "Admin" | "Task" | "UI";
  origin: string;

  subject: AuditResponseSubject;
  subjectId: string;

  timestamp: string;
}

interface UserOption {
  id: number;
  fullName: string;
  email: string;
}

type WithUserOptions<T extends object> = T & { users: UserOption[] };

const logListSchema = paginationSchema.extend({
  sortBy,
});

type LogListFilter = z.infer<typeof logListSchema>;

const sortLogs = (description: SortDescriptor) =>
  function compareFn(a: AuditLog, b: AuditLog): number {
    const comparison = parseAbsoluteToLocal(a.timestamp).compare(
      parseAbsoluteToLocal(b.timestamp),
    );

    if (description.direction === "ascending") {
      return comparison * -1;
    }

    return comparison;
  };

const logsQueryOptions = (
  opts: LogListFilter = {
    limit: 30,
    offset: 0,
    sortBy: "desc",
  },
) =>
  infiniteQueryOptions<
    WithUserOptions<DataWithPagination<AuditLog>>,
    HTTPError
  >({
    queryKey: ["logs", { sortBy: opts.sortBy }],
    staleTime: 30_000,
    placeholderData: keepPreviousData,

    getPreviousPageParam: (firstPage) => firstPage?.previous ?? null,
    getNextPageParam: (lastPage) => lastPage?.next ?? null,
    initialPageParam: `?limit=${opts.limit}&offset=${opts.offset}${opts.sortBy === "asc" ? "&ordering=timestamp" : ""}`,

    maxPages: 3,

    refetchInterval: 10_000,

    queryFn: async ({ signal, pageParam }) => {
      const data = await api
        .get("audit/record/", {
          searchParams: mergeParams(
            opts,
            typeof pageParam === "string" ? pageParam : null,
          ),
          signal,
        })
        .json<WithUserOptions<DataWithPagination<Omit<AuditLog, "id">>>>()
        .then((res) => {
          const results: AuditLog[] = [];

          res.results.forEach((result, index, arr) => {
            results.push({
              ...arr[index],
              id: ["log", result.subjectId, result.timestamp, index].join(":"),
            });
          });

          res.results = results;

          return res as WithUserOptions<DataWithPagination<AuditLog>>;
        });

      return data;
    },
  });

export {
  logListSchema,
  type LogListFilter,
  logsQueryOptions,
  type AuditLog,
  sortLogs,
};

import { useEffect, useRef, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { cn } from "@dokworks/shared";
import { Spinner } from "@dokworks/ui";

const dossierSearchSchema = z.object({
  page: z.number().catch(0),
  section: z.number().catch(0),
});

export const Route = createFileRoute("/_auth/dossier/$dossierId/edit")({
  validateSearch: dossierSearchSchema,
  component: DossierIndexComponent,
});

function DossierIndexComponent() {
  const { dossierId } = Route.useParams();

  const [iframeIsLoading, setIframeIsLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setIframeIsLoading(true);
    iframeRef.current!.src = `/vue/dossier/${dossierId}/${window.location.search}}`;
  }, [dossierId, setIframeIsLoading]);

  return (
    <div className="flex flex-1 gap-2">
      <div
        className={cn(
          "flex flex-1 gap-2 font-semibold",
          iframeIsLoading ? "flex" : "hidden",
        )}
      >
        <div className="floating flex w-xsmall items-center justify-center gap-2">
          <Spinner className="text-fg-accent" /> Loading...
        </div>
        <div className="floating flex flex-1 items-center justify-center gap-2 ">
          <Spinner className="text-fg-accent" /> Loading...
        </div>
      </div>
      <iframe
        ref={iframeRef}
        className={cn("flex-1", iframeIsLoading && "hidden")}
        onLoad={() => {
          setIframeIsLoading(false);
        }}
      ></iframe>
    </div>
  );
}

import type { FC } from "react";

import {
  CheckmarkCircle12Filled,
  DismissCircle12Filled,
} from "@fluentui/react-icons";
import { useDateFormatter, type DateFormatterOptions } from "react-aria";
import { ProgressBar } from "react-aria-components";

import { cn, type WorkflowAutomation } from "@dokworks/shared";
import { Cell, Column, Row, Table, TableBody, TableHeader } from "@dokworks/ui";

import { StatusPlate } from "@/components/workflow/statusPlate.workflow";

type WorkflowAutomationTableProps = {
  items: WorkflowAutomation[];
};

const options: DateFormatterOptions = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

export const WorkflowAutomationTable: FC<WorkflowAutomationTableProps> = ({
  items,
}) => {
  const dateFormatter = useDateFormatter(options);
  return (
    <Table aria-label="Workflow automations" className="h-[200px] rounded-md">
      <TableHeader>
        <Column isRowHeader textValue="Descriptie" defaultWidth={360}>
          Descriptie
        </Column>
        <Column textValue="Status" maxWidth={140}>
          Status
        </Column>
        <Column textValue="Voortgang" minWidth={120}>
          Voortgang
        </Column>
        <Column textValue="Details" defaultWidth={360} maxWidth={600}>
          Details
        </Column>
        <Column textValue="Aangemaakt op" width={240}>
          Aangemaakt op
        </Column>
        <Column textValue="Laatst aangepast op" width={240}>
          Laatst aangepast op
        </Column>
      </TableHeader>
      <TableBody
        items={items}
        renderEmptyState={() => (
          <p className="p-2 text-center">
            Geen automatische workflows gevonden.
          </p>
        )}
      >
        {function renderRow(item) {
          const createdAt = dateFormatter.format(new Date(item.createdAt));
          const updatedAt = dateFormatter.format(new Date(item.updatedAt));
          const maxSteps = item.data.steps.length;

          const fulfilledDetails: string[] = [];
          const unfulfilledDetails: string[] = [];
          const details = Object.keys(item.data.statusDetail);

          for (const detail of details) {
            (item.data.statusDetail[detail]
              ? fulfilledDetails
              : unfulfilledDetails
            ).push(detail);
          }

          return (
            <Row id={item.id}>
              <Cell textValue={item.name}>{item.name}</Cell>
              <Cell textValue={item.data.status}>
                <StatusPlate workflow={item} />
              </Cell>
              <Cell textValue={`${item.data.step} / ${maxSteps}`}>
                <ProgressBar
                  aria-label="Voortgang"
                  minValue={0}
                  value={item.data.step}
                  maxValue={maxSteps}
                  className="mx-1"
                >
                  {({ percentage, valueText }) => (
                    <span className="grid gap-1">
                      <span className="flex items-center justify-between px-1 text-xs tracking-wider">
                        <span>{`${item.data.step} / ${maxSteps}`}</span>
                        <span>{valueText}</span>
                      </span>
                      <span className="block h-2 flex-1 rounded-full bg-canvas-muted shadow-inner">
                        <span
                          style={{
                            width: `${percentage}%`,
                          }}
                          className={cn(
                            "block h-full rounded-full bg-canvas-accent-emphasis",
                            {
                              "rounded-r-sm": percentage && percentage < 100,
                            },
                          )}
                        ></span>
                      </span>
                    </span>
                  )}
                </ProgressBar>
              </Cell>
              <Cell textValue={details.join(", ")}>
                {fulfilledDetails.map((detail, index) => (
                  <span
                    key={item.id + ":" + detail + ":y:" + index}
                    className="inline-flex items-center gap-1 rounded-full border-brd-severe-emphasis bg-canvas-severe-muted px-2 py-1 text-xs font-medium text-fg-severe"
                  >
                    <span>{detail}</span>
                    <CheckmarkCircle12Filled />
                  </span>
                ))}
                {unfulfilledDetails.map((detail, index) => (
                  <span
                    key={item.id + ":" + detail + ":n:" + index}
                    className="inline-flex items-center gap-1 rounded-full border-brd-danger-emphasis bg-canvas-danger-muted px-2 py-1 text-xs font-medium text-fg-danger"
                  >
                    <span>{detail}</span>
                    <DismissCircle12Filled />
                  </span>
                ))}
              </Cell>
              <Cell textValue={updatedAt}>{updatedAt}</Cell>
              <Cell textValue={createdAt}>{createdAt}</Cell>
            </Row>
          );
        }}
      </TableBody>
    </Table>
  );
};

import {
  createFileRoute,
  Navigate,
  Outlet,
  useMatchRoute,
} from "@tanstack/react-router";

import { cn } from "@dokworks/shared";

import {
  // dossierQueryOptions,
  dossierSearchSchema,
} from "@/utils/fetch/dossier";
import { logsQueryOptions } from "@/utils/fetch/log";

export const Route = createFileRoute("/_auth/dossier/$dossierId")({
  validateSearch: dossierSearchSchema,
  component: DossierComponent,
  loaderDeps: ({ search }) => ({
    workflowFilter: search.workflowFilter,
    logFilter: search.logFilter,
  }),
  loader: async ({ context, deps }) => {
    // TODO implement dossier context
    // context.queryClient.prefetchQuery(dossierQueryOptions(params.dossierId));
    context.queryClient.prefetchInfiniteQuery(logsQueryOptions(deps.logFilter));
  },

  notFoundComponent: DossierNotFoundComponent,
});

function DossierComponent() {
  const matchRoute = useMatchRoute();

  const auditIsOpen = matchRoute({
    to: "/dossier/$dossierId",
    search: {
      logFilter: {},
    },
    fuzzy: true,
  });

  return (
    <div
      className={cn("grid-rows-fr grid flex-1 basis-0 auto-cols-fr", {
        "gap-2": auditIsOpen,
      })}
    >
      <Outlet />
    </div>
  );
}

function DossierNotFoundComponent() {
  const params = Route.useParams();

  return (
    <Navigate
      to="/dossier/$dossierId/edit"
      params={params}
      search={{ page: 0, section: 0 }}
      replace
    />
  );
}
